<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      魔都代购之家成立于2017年，已经成为众多代购的首选渠道，2019年魔都代购v1.0上线，正式发布APP运营.
    </p>
    <h3>软件截图</h3>
    <ul>
      <img class="pic" src="https://images.project1.team/IMG_4035.PNG" />
      <img class="pic" src="https://images.project1.team/IMG_4036.PNG" />
      <img class="pic" src="https://images.project1.team/IMG_4037.PNG" />
      <img class="pic" src="https://images.project1.team/IMG_4038.PNG" />
    </ul>
    <ul>
      <a class="app" href="https://apps.apple.com/cn/app/魔都代购之家/id1490682349?l=en" target="_blank"><img
          src="https://images.project1.team/iOSapp-download-zh-CN.png" /></a>
      <a class="app" href="https://dg-home.oss-cn-shanghai.aliyuncs.com/androidApp/daigo.apk" target="_blank"><img
          src="https://images.project1.team/download-for-android.png" /></a>
    </ul>
    <!-- <h3>Essential Links</h3>
    <ul>
      <li>
        <a href="https://vuejs.org" target="_blank" rel="noopener">Core Docs</a>
      </li>
      <li>
        <a href="https://forum.vuejs.org" target="_blank" rel="noopener"
          >Forum</a
        >
      </li>
      <li>
        <a href="https://chat.vuejs.org" target="_blank" rel="noopener"
          >Community Chat</a
        >
      </li>
      <li>
        <a href="https://twitter.com/vuejs" target="_blank" rel="noopener"
          >Twitter</a
        >
      </li>
      <li>
        <a href="https://news.vuejs.org" target="_blank" rel="noopener">News</a>
      </li>
    </ul> -->
    <!-- <h3>售后/联系方式</h3>
    <ul>
      <a target="_blank" href="http://mail.qq.com/cgi-bin/qm_share?t=qm_mailme&email=OAkNDwEOCgANDwB4SUkWW1dV"
        style="text-decoration: none"><img
          src="http://rescdn.qqmail.com/zh_CN/htmledition/images/function/qm_open/ico_mailme_02.png" /></a>
    </ul> -->
    <div style="height: 60px" />
    <div>© 2022. All Rights Reserved.上海成乾信息科技有限公司版权所有</div>
    <div><a href="https://beian.miit.gov.cn">沪ICP备2022019205号</a></div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.pic {
  width: 10%;
  height: 10%;
  margin: 10px;
}

.app {
  margin: 10px;
}
</style>
